<template>
  <div class="wrap">
    <el-tabs v-model="activeName" class="tabs">
      <!-- 常规任务 -->
      <el-tab-pane label="常规任务" name="first">
        <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
         <el-form-item>
            <el-select
              v-model="queryParams.status"
              placeholder="选择年份"
              @change="resetPageAndRefresh"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryParams.status"
              placeholder="选择月份"
              @change="resetPageAndRefresh"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="big-box">
          <div class="box-style-b" >
            <div class="head">
              <div class="head-top">
                <span class="rule">常规任务</span>
                <span class="state1 tinctblue fr">待审核</span>
              </div>
              <div class="area">
                <p class="con-txt">2021-3月份</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-经开区</p>
                <p class="mission">任务区域</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
              <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel">任务详情</el-button>
              <el-button class="footer-btn" @click="apportiondel">分配详情</el-button>
              <!-- <el-button class="footer-btn" @click="taskapp">任务分配</el-button> -->
              <!-- <el-button class="footer-btn" v-else-if="">继续分配</el-button>
              <el-button class="footer-btn" v-else>分配详情</el-button> -->
            </div>
          </div>  
          <div class="box-style-b">
            <div class="head">
              <div class="head-top">
                <span class="rule">常规任务</span>
                <span class="state1 tinctgreen fr">已通过</span>
              </div>
              <div class="area">
                <p class="con-txt">2021-5月份</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-肥西县</p>
                <p class="mission">任务区域</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
              <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel">任务详情</el-button>
              <el-button class="footer-btn" @click="apportiondel">分配详情</el-button>
              <!-- <el-button class="footer-btn" @click="taskapp">任务分配</el-button> -->
              <!-- <el-button class="footer-btn" v-else-if="">继续分配</el-button>
              <el-button class="footer-btn" v-else>分配详情</el-button> -->
            </div>
          </div> 
          <div class="box-style-b">
            <div class="head">
              <div class="head-top">
                <span class="rule">常规任务</span>
                <span class="state1 tinctred fr">未通过</span>
              </div>
              <div class="area">
                <p class="con-txt">2021-上半年</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-瑶海区</p>
                <p class="mission">任务区域</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
              <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel">任务详情</el-button>
              <el-button class="footer-btn" @click="apportiondel">分配详情</el-button>
              <!-- <el-button class="footer-btn" @click="taskapp">任务分配</el-button> -->
              <!-- <el-button class="footer-btn" v-else-if="">继续分配</el-button>
              <el-button class="footer-btn" v-else>分配详情</el-button> -->
            </div>
          </div> 
        </div>
      </el-tab-pane>
      <!-- 奖励任务 -->
      <el-tab-pane label="奖励任务" name="second">
        <!-- <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
         <el-form-item>
            <el-select
              v-model="queryParams.status"
              placeholder="选择年份"
              @change="resetPageAndRefresh"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryParams.status"
              placeholder="选择月份"
              @change="resetPageAndRefresh"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form> -->
        <div class="big-box">
          <div class="box-style-b">
            <div class="head">
              <div class="head-top">
                <span class="rules">奖励任务</span>
                <span class="state1 fr">待审核</span>
                <!-- <span class="state2 fr">未通过</span> -->
                <!-- <span class="state3 fr">已通过</span> -->
              </div>
              <div class="area">
                <p class="con-txt">2020-3月份</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-经开区</p>
                <p class="mission">任务区域</p>
              </div>
              <div class="area">
                <p class="con-txt">500</p>
                <p class="mission">奖励金额</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
               <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel">任务详情</el-button>
              <el-button class="footer-btn" @click="apportiondel">分配详情</el-button>
              <!-- <el-button class="footer-btn" @click="taskapp">任务分配</el-button> -->
              <!-- <el-button class="footer-btn" v-else-if="">继续分配</el-button>
              <el-button class="footer-btn" v-else>分配详情</el-button> -->
          </div>  
          </div>
          <div class="box-style-b">
            <div class="head">
              <div class="head-top">
                <span class="rules">奖励任务</span>
                <!-- <span class="state1 fr">待审核</span> -->
                <span class="state2 fr">未通过</span>
                <!-- <span class="state3 fr">已通过</span> -->
              </div>
              <div class="area">
                <p class="con-txt">2020-3月份</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-瑶海区</p>
                <p class="mission">任务区域</p>
              </div>
              <div class="area">
                <p class="con-txt">500</p>
                <p class="mission">奖励金额</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
              <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel">任务详情</el-button>
              <el-button class="footer-btn" @click="apportiondel">分配详情</el-button>
              <!-- <el-button class="footer-btn" @click="taskapp">任务分配</el-button> -->
              <!-- <el-button class="footer-btn" v-else-if="">继续分配</el-button>
              <el-button class="footer-btn" v-else>分配详情</el-button> -->
          </div>  
          </div>
          <div class="box-style-b">
            <div class="head">
              <div class="head-top">
                <span class="rules">奖励任务</span>
                <!-- <span class="state1 fr">待审核</span> -->
                <!-- <span class="state2 fr">未通过</span> -->
                <span class="state3 fr">已通过</span>
              </div>
              <div class="area">
                <p class="con-txt">2020-3月份</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-蜀山区</p>
                <p class="mission">任务区域</p>
              </div>
              <div class="area">
                <p class="con-txt">500</p>
                <p class="mission">奖励金额</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
              <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel">任务详情</el-button>
              <el-button class="footer-btn" @click="apportiondel">分配详情</el-button>
              <!-- <el-button class="footer-btn" @click="taskapp">任务分配</el-button> -->
              <!-- <el-button class="footer-btn" v-else-if="">继续分配</el-button>
              <el-button class="footer-btn" v-else>分配详情</el-button> -->
          </div>  
          </div>
        </div>
      </el-tab-pane>
      <!-- 学习任务 -->
      <el-tab-pane label="学习任务" name="third">
        <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
         <el-form-item>
            <el-select
              v-model="queryParams.status"
              placeholder="选择年份"
              @change="resetPageAndRefresh"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryParams.status"
              placeholder="选择月份"
              @change="resetPageAndRefresh"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="big-box">
          <div class="box-style-b" v-for="item in 3" :key="item">
            <div class="head">
              <div class="head-top">
                <span class="rules" style="background:#4A90E2;">学习任务</span>
                <span class="state1 fr">待审核</span>
                <!-- <span class="state2 fr">未通过</span> -->
                <!-- <span class="state3 fr">已通过</span> -->
              </div>
              <div class="area">
                <p class="con-txt">2020-3月份</p>
                <p class="mission">任务时间</p>
              </div>
              <div class="area">
                <p class="con-txt">安徽省-合肥市-经开区</p>
                <p class="mission">任务区域</p>
              </div>
              <div class="area">
                <p class="con-txt">成强</p>
                <p class="mission">CEO</p>
              </div>
            </div>
            <div class="content">
              <p class="content-tit">任务内容</p>
               <div>
                <p class="content-con"><span class="con-con-tit">通用任务</span> <span>移动号卡(张)：800、</span><span>营业额(元)：3000、</span><span>订单数(笔)：200、</span></p>
                <p class="content-con"><span class="con-con-tit">商户推广</span> <span>运营商：3家、</span><span>考证培训：3家、</span><span>考证培训：3家、</span> <span>大型超市：3家</span> </p>
                <p class="content-con"><span class="con-con-tit">对接应用</span> <span>1、对接一卡通：1、对接教务系统：1、对接共享服务：1、社团招募…</span></p>
                <p>...</p>
              </div>
            </div>
            <div class="footer">
              <el-button class="footer-btn" @click="taskdel" style="width:100%">任务详情</el-button>
              <!-- <el-button class="footer-btn" @click="apportiondel">分配详情</el-button> -->
              <!-- <el-button class="footer-btn" @click="taskapp">任务分配</el-button> -->
              <!-- <el-button class="footer-btn" v-else-if="">继续分配</el-button>
              <el-button class="footer-btn" v-else>分配详情</el-button> -->
          </div>  
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 分解详情 -->
    <el-drawer
      append-to-body
      title="分配详情"
      :visible.sync="drawer"
      size="35%"
      >
      <div class="allocationdetail">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt">2021-3月份</p>
              <p class="mission">任务类型</p>
            </div>
            <div class="area">
              <p class="con-txt">安徽省-合肥市-经开区</p>
              <p class="mission">任务时间</p>
            </div>
            <div class="area fr">
              <p class="con-txt">已分配</p>
              <p class="mission">分配状态</p>
            </div>
          </div>
          <div class="headcon">
            <p class="contit">分配人</p>
            <img src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2640900762,1357209539&fm=26&gp=0.jpg" class="pig" alt="">
            <div class="area">
              <p class="con-txt">王小二</p>
              <p class="mission">区域经理</p>
            </div>
            <div class="area">
              <p class="con-txt">18325626720</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">安徽省-合肥市-经开区</p>
              <p class="mission">任务区域</p>
            </div>
          </div>
        </div>
        <div class="d-con">
          <p class="contit">已分配详情</p>
          <el-collapse v-model="unfold" accordion>
            <el-collapse-item name="1">
              <div slot="title" class="colltit">
                <span class="college">中国科技大学</span>
                <div class="people">
                  <span>CEO：</span>
                  <span>李晓（17855664235）</span>
                </div>
              </div>
              <div class="collcon">
                <div class="conhead">
                  <span>分配时间：</span>
                  <span>2019-08-12 14:34</span>
                </div>
                <div class="content">
                  <div class="taskcon">
                    <p class="contit">通用任务</p>
                    <div class="conitem">
                      <div class="conlab">移动号卡（张）</div>
                      <div class="connum">800  </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">营业额（元）</div>
                      <div class="connum">3000  </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">订单数（笔）</div>
                      <div class="connum">200</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">访客数（人）</div>
                      <div class="connum">500</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">商户推广</p>
                    <div class="conitem">
                      <div class="conlab">运营商（家）</div>
                      <div class="connum">300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">考证培训（家）</div>
                      <div class="connum">20</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">餐饮商家（家）</div>
                      <div class="connum">300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">大型超市（家）</div>
                      <div class="connum">30</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">广告招商（家）</div>
                      <div class="connum">50</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">对接应用</p>
                    <div class="conitem">
                      <div class="conlab">对接一卡通（个）</div>
                      <div class="connum">800</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接教务系统（个）</div>
                      <div class="connum">20</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接共享服务（个）</div>
                      <div class="connum">400</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接其他应用（个）</div>
                      <div class="connum">200</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">线下举办活动（个）</div>
                      <div class="connum">30</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <!-- <el-collapse-item name="2">
              <div slot="title" class="colltit">
                <span class="college">中国科技大学</span>
                <div class="people">
                  <span>CEO：</span>
                  <span>李晓（17855664235）</span>
                </div>
              </div>
              <div class="collcon">
                <div class="conhead">
                  <span>分配时间：</span>
                  <span>2019-08-12 14:34</span>
                </div>
                <div class="content">
                  <div class="taskcon">
                    <p class="contit">通用任务</p>
                    <div class="conitem">
                      <div class="conlab">营业额（元）</div>
                      <div class="connum">5300  </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">订单数（笔）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">访客数（人）</div>
                      <div class="connum">5300</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">商户推广</p>
                    <div class="conitem">
                      <div class="conlab">运营商（家）</div>
                      <div class="connum">5300  </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">考证培训（家）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">餐饮商家（家）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">大型超市（家）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">广告招商（家）</div>
                      <div class="connum">5300</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">对接应用</p>
                    <div class="conitem">
                      <div class="conlab">对接一卡通（个）</div>
                      <div class="connum">5300  </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接教务系统（个）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接共享服务（个）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接其他应用（个）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">线下举办活动（个）</div>
                      <div class="connum">5300</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <div slot="title" class="colltit">
                <span class="college">中国科技大学</span>
                <div class="people">
                  <span>CEO：</span>
                  <span>李晓（17855664235）</span>
                </div>
              </div>
              <div class="collcon">
                <div class="conhead">
                  <span>分配时间：</span>
                  <span>2019-08-12 14:34</span>
                </div>
                <div class="content">
                  <div class="taskcon">
                    <p class="contit">通用任务</p>
                    <div class="conitem">
                      <div class="conlab">营业额（元）</div>
                      <div class="connum">5300  </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">订单数（笔）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">访客数（人）</div>
                      <div class="connum">5300</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">商户推广</p>
                    <div class="conitem">
                      <div class="conlab">运营商（家）</div>
                      <div class="connum">5300  </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">考证培训（家）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">餐饮商家（家）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">大型超市（家）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">广告招商（家）</div>
                      <div class="connum">5300</div>
                    </div>
                  </div>
                  <div class="taskcon">
                    <p class="contit">对接应用</p>
                    <div class="conitem">
                      <div class="conlab">对接一卡通（个）</div>
                      <div class="connum">5300  </div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接教务系统（个）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接共享服务（个）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">对接其他应用（个）</div>
                      <div class="connum">5300</div>
                    </div>
                    <div class="conitem">
                      <div class="conlab">线下举办活动（个）</div>
                      <div class="connum">5300</div>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item> -->
          </el-collapse>
          <el-button class="taskdetails" @click="apptask">任务详情</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 任务详情 -->
    <el-drawer
      append-to-body
      :visible.sync="innerDrawer"
      size="35%"
      >
      <div slot="title"> 
        <i @click="goback" v-if="drawer==true"> < </i>
        任务详情
      </div>
      <div class="taskdetail">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt">常规任务</p>
              <p class="mission">任务类型</p>
            </div>
            <div class="area">
              <p class="con-txt">2021-3月份</p>
              <p class="mission">任务时间</p>
            </div>
            <div class="area fr">
              <p class="con-txt">2021-04-18 16:39</p>
              <p class="mission">分配时间</p>
            </div>
          </div>
          <div class="headcon">
            <p class="contit">接收人</p>
            <img  src="https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2640900762,1357209539&fm=26&gp=0.jpg" class="pig" alt="">
            <div class="area">
              <p class="con-txt">王小二</p>
              <p class="mission">区域经理</p>
            </div>
            <div class="area">
              <p class="con-txt">18325626720</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">安徽省-合肥市-经开区</p>
              <p class="mission">任务区域</p>
            </div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">通用任务</p>
          <div class="conitem">
            <div class="conlab">移动号卡（张）</div>
            <div class="connum">800  </div>
          </div>
          <div class="conitem">
            <div class="conlab">营业额（元）</div>
            <div class="connum">3000  </div>
          </div>
          <div class="conitem">
            <div class="conlab">订单数（笔）</div>
            <div class="connum">200</div>
          </div>
          <div class="conitem">
            <div class="conlab">访客数（人）</div>
            <div class="connum">5000</div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">商户推广</p>
          <div class="conitem">
            <div class="conlab">运营商（家）</div>
            <div class="connum">3</div>
          </div>
          <div class="conitem">
            <div class="conlab">考证培训（家）</div>
            <div class="connum">3</div>
          </div>
          <div class="conitem">
            <div class="conlab">餐饮商家（家）</div>
            <div class="connum">300</div>
          </div>
          <div class="conitem">
            <div class="conlab">大型超市（家）</div>
            <div class="connum">3</div>
          </div>
          <div class="conitem">
            <div class="conlab">广告招商（家）</div>
            <div class="connum">20</div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">对接应用</p>
          <div class="conitem">
            <div class="conlab">对接一卡通（个）</div>
            <div class="connum">800  </div>
          </div>
          <div class="conitem">
            <div class="conlab">对接教务系统（个）</div>
            <div class="connum">40</div>
          </div>
          <div class="conitem">
            <div class="conlab">对接共享服务（个）</div>
            <div class="connum">400</div>
          </div>
          <div class="conitem">
            <div class="conlab">对接其他应用（个）</div>
            <div class="connum">200</div>
          </div>
          <div class="conitem">
            <div class="conlab">线下举办活动（个）</div>
            <div class="connum">30</div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">用户服务</p>
          <div class="conitem">
            <div class="conlab">客户服务（个）</div>
            <div class="connum">10</div>
          </div>
          <div class="conitem">
            <div class="conlab">上门服务（个）</div>
            <div class="connum">10</div>
          </div>
          <div class="conitem">
            <div class="chang">商务合作联系方式维护（个）</div>
            <div class="duan">5</div>
          </div>
        </div>
        <div class="taskcon">
          <p class="contit">用户运营</p>
          <div class="conitem">
            <div class="conlab">客户服务（个）</div>
            <div class="connum">20</div>
          </div>
          <div class="conitem">
            <div class="conlab">上门服务（个）</div>
            <div class="connum">20</div>
          </div>
          <div class="conitem">
            <div class="chang">商务合作联系方式维护（个）</div>
            <div class="duan">3</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'resolveMessage',
  components: {
    PagedList
  },
  mixins: [pageMixin],
  data() {
    return{
      activeName:'first',
      queryParams: {

      },
      statusOptions:[],
      drawer: false,
      innerDrawer: false,
      unfold:'first',
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('还有未保存的工作哦确定关闭吗？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    // 任务分配
    taskapp() {
      this.$router.push({
        path: '/taskAllocation'
      })
    },
    // 分配详情
    apportiondel() {
      this.drawer = true
    }, 
    // 任务详情
    taskdel() {
      this.innerDrawer = true
    },
    // 分配详情中的任务详情按钮
    apptask() {
      this.innerDrawer = true
    },
    // 任务详情返回
    goback() {
      this.innerDrawer = false
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .tabs{ width: 100%; min-height: 100%; background:#fff;padding: 20px;padding-top: 0;
  }
  .big-box{ width: 100%; height: 100%; 
  }
}

#el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
.el-drawer__header{ margin-bottom: 0px;}
.allocationdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
  .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
    .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
      .area{display: inline-block; margin-right: 16px;
        .con-txt{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 18px;}
        .mission{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
    }
    .headcon{
      .contit{ color: $c-2; padding: 20px 0; font-size: 16px; font-weight: bolder;}
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 50%;}
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
  }
  .d-con{
    .contit{ color: $c-9; padding: 20px 0; font-size: 16px;}
    .el-collapse{ border: 0; }
    .el-collapse-item{ margin-bottom: 20px; }
    .el-collapse-item__content{padding: 0;}
    .el-collapse-item .is-active{ background:$c-success; border: 1px solid #28B892; color:#fff; }
    .el-collapse-item__header{ background: #e2e7eb; height: 44px; font-size: 16px; color: $c-2; border-radius: 4px; border: 1px solid #e2e7eb;}
    .el-collapse-item__header .is-active{color: #fff;}
    .el-collapse-item__arrow .el-icon-arrow-right{ color: $c-2; }
    .el-collapse-item__arrow .el-icon-arrow-right .is-active{ color: #fff; }
    .colltit{ padding:0 20px;
      .people{ display: inline-block; margin-left:50px;}
    }
    .collcon{ padding: 20px; background: $c-border;min-height: 300px;padding-right: 0;
      .conhead{ color: $c-2; font-size: 16px; font-family: PingFangSC-Semibold, PingFang SC;line-height: 20px;
        span{  font-weight: bold; }
      }
      .content{
        .taskcon {
          .contit{ color: $c-1; padding: 10px 0; font-size: 14px; font-weight: bold; }
          .conitem{ display: inline-block; 
            .conlab, .chang{ color: $c-9; display: inline-block; min-width: 130px;}
            .connum{ display: inline-block; width: 100px; }
            .duan { display: inline-block; }
          }
        }
      }
    }
    .taskdetails{ width:120px; margin-left: 50%; transform: translateX(-50%); margin-top: 20px;}
  }
}
.taskdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
  .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
    .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
      .area{display: inline-block; margin-right: 16px;
        .con-txt{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 18px;}
        .mission{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
    }
    .headcon{
      .contit{ color: $c-2; padding: 20px 0; font-size: 16px; font-weight: bold; }
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 50%; }
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
  }
  .taskcon { padding: 10px 0;
    .contit{ color: $c-1; padding: 10px 0; font-size: 14px; font-weight: bold; }
    .conitem{ display: inline-block; width: 240px;
      .conlab, .chang{ color: $c-9; display: inline-block; width: 130px; }
      .chang { width: 200px; }
      .connum{ display: inline-block; width: 100px; }
      .duan { display: inline-block; }
    }
  }
}

</style>
